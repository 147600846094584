import React, {useContext} from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  workExperiences,
  skillsSection,
  resumeSection
} from "../../portfolio";

export default function Header() {
  const {isDark} = useContext(StyleContext);

  const handleResumeClick = (e) => {
    e.preventDefault();
    if (resumeSection.resumeLink) {
      window.open(resumeSection.resumeLink, "_blank");
    }
  };

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <a href="/" className="logo">
          <span className="grey-color"> &lt;</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">/&gt;</span>
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        <ul className="menu">
          {skillsSection.display && (
            <li>
              <a href="#skills">Skills</a>
            </li>
          )}
          {workExperiences.display && (
            <li>
              <a href="#experience">Experience</a>
            </li>
          )}
          {resumeSection.display && (
            <li>
              <a 
                href={resumeSection.resumeLink}
                onClick={handleResumeClick}
                target="_blank"
                rel="noopener noreferrer"
                className="resume-nav-link"
              >
                Resume
              </a>
            </li>
          )}
          <li>
            <a href="#contact">Contact Me</a>
          </li>
          <li className="toggle-switch-container">
            <ToggleSwitch />
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
